import React from 'react'

import Layout from '../components/layout'

const IndexPage = () => (
    <Layout>
        <h1>Whits Garden, LLC.</h1>
        <p>Site being redesigned. Stay tuned.</p>
    </Layout>
)

export default IndexPage
